<template>
  <b-form-checkbox
    v-model="newStatus"
    :id="id"
    type="text"
    :checked="checked"
    :switch="isSwitch"
    :size="size"
  ></b-form-checkbox>
</template>

<script>
export default {
  model: {
    prop: 'checkValue',
    event: 'setStatus'
  },
  props: {
    checked: {
      type: Boolean,
      require: false,
      default: false
    },
    checkValue: {
      type: Boolean,
      require: false,
      default: false
    },
    id: {
      type: String,
      require: false
    },
    isSwitch: {
      type: Boolean,
      require: false,
      default: false
    },
    size: {
      type: String,
      require: false,
      default: 'md'
    }
  },

  computed: {
    newStatus: {
      get() {
        return this.checkValue;
      },
      set(value) {
        this.$emit('setStatus', value);
      }
    }
  }
};
</script>

<style></style>
